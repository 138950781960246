.swal2-container {
    &.swal2-backdrop-show,
    &.swal2-noanimation {
        background: rgba($gray-800,.8) !important;
    }
}

.swal2-popup {
    color: $body-color !important;
    border: 0 !important;
}

.swal2-title {
    color: $gray-800 !important;
    font-weight: $font-weight-extrabold !important;
}

.swal2-content {
    color: $gray-600 !important;
}

.swal2-styled {
    padding: $input-btn-padding-y $input-btn-padding-x;
    &.swal2-confirm {
        background-color: $info !important;
        border-radius: $border-radius !important;
    }
}

.swal2-icon {
    .swal2-icon-content {
        font-size: 2.5em !important;
    }
    &.swal2-success {
        [class^="swal2-success-circular-line"] {
            // Emulate moving circular line
            opacity: 0;
        }
        .swal2-success-fix {
            // Hide corners left from animation
            opacity: 0;
        }

        // Success icon animation
        &.swal2-icon-show {
            @if $swal2-icon-animations {
                .swal2-success-line-tip {
                    animation: swal2-animate-success-line-tip 0.55s;
                }

                .swal2-success-line-long {
                    animation: swal2-animate-success-line-long 0.55s;
                }

                .swal2-success-circular-line-right {
                    animation: swal2-rotate-success-circular-line 3.25s ease-in;
                }
            }
        }
    }
}
