.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-target {
    position: relative;
    direction: ltr;
}

.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

/* Wrapper for all connect elements.
  */
.noUi-connects {
    overflow: hidden;
    z-index: 0;
}

.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 10%;
    width: 10%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
}

.noUi-connect {
    height: 100%;
    width: 100%;
}

/* Offset direction
  */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    left: 0;
    right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
  * connect elements.
  */
.noUi-vertical .noUi-origin {
    width: 0;
}

.noUi-horizontal .noUi-origin {
    height: 0;
}

.noUi-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}

.noUi-touch-area {
    height: 100%;
    width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.noUi-state-drag * {
    cursor: inherit !important;
}

/* Slider size and handle placement;
  */
.noUi-horizontal {
    height: $noui-target-thickness;
}

.noUi-horizontal .noUi-handle {
    width: 34px;
    height: 28px;
    right: -17px;
    top: -6px;
}

.noUi-vertical {
    width: $noui-target-thickness;
    height: 340px;
}

.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    right: -6px;
    top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    left: -17px;
    right: auto;
}

/* Styling;
  * Giving the connect element a border radius causes issues with using transform: scale
  */
.noUi-target {
    background: #E5E7EB;
    border-radius: 0.5rem;
    border: $border-width solid #d1d7e0;
    box-shadow: inset 0 1px 1px #eaedf2, 0 3px 6px -5px #d1d7e0;
}

.noUi-connects {
    border-radius: 3px;
}

.noUi-connect {
    background: $noui-slider-connect-bg;
}

/* Handles and cursors;
  */
.noUi-draggable {
    cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}

.noUi-handle {
    border: $border-width solid #d1d7e0;
    border-radius: 50%;
    background: $white;
    cursor: default;
    outline: none;
    // Change the cursor to a grab hand on hover.
    &:hover {
        cursor: grab;
        cursor: -webkit-grab;
        cursor: -moz-grab;
        @include box-shadow($noui-handle-active-shadow);
    }

    // Change the cursor to a grabbing hand on active.
    &:active {
        cursor: grabbing;
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        @include box-shadow($noui-handle-active-shadow);
    }

    &:focus {
        @include box-shadow($noui-handle-active-shadow);
    }
}

.noUi-active {
    outline: none;
}

/* Handle stripes;
  */
.noUi-handle:before,
.noUi-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: #e8e7e6;
    left: 14px;
    top: 6px;
}

.noUi-handle:after {
    left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.noUi-vertical .noUi-handle:after {
    top: 17px;
}

/* Disabled state;
  */
[disabled] .noUi-connect {
    background: #b8b8b8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}

/* Base;
  *
  */
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.noUi-pips {
    position: absolute;
    color: #999;
}

/* Values;
  *
  */
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}

/* Markings;
  *
  */
.noUi-marker {
    position: absolute;
    background: #ccc;
}

.noUi-marker-sub {
    background: #aaa;
}

.noUi-marker-large {
    background: #aaa;
}

/* Horizontal layout;
  *
  */
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}

/* Vertical layout;
  *
  */
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.noUi-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}

.noUi-tooltip {
    display: block;
    position: absolute;
    border: $border-width solid #d1d7e0;
    border-radius: 0.5rem;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px;
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    top: auto;
    right: 28px;
}

.noUi-horizontal {
    height: $noui-slider-height;

    .noUi-handle {
        width: 25px;
        height: 25px;
        top: -9px;

        &:focus {
            box-shadow: $noui-focus-shadow;
            outline: none;
            cursor: pointer;
        }

        &::before {
            right: 8px;
            top: 5px;
            background: $noui-border-color;
        }

        &::after {
            left: 8px;
            top: 5px;
            background: $noui-border-color;
        }
    }

    .noUi-tooltip {
        bottom: 135%;
    }
}

.noUi-tooltip {
    color: $gray;
    font-size: $font-size-sm;
    border-radius: 0.5rem;
    border: $border-width solid $noui-border-color;
}

.noUi-connect {
    background: $noui-connect-bg;
}

.noUi-connects {
    border-radius: $border-radius;
}
