div#businessUnitGrid,
div#detailedGrid 
, div#backlogGrid
{
    height: 300px;
    overflow-y: auto;
    text-align: justify;
    font-size: 12px;
    font-family: '"Inter", sans-serif';
}

@media screen and (max-width: 1216px) {
    td.name {
        max-width: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.divClass {
    height: 100%
}

svg.text {
    font-size: 12px;
}

div.container {
    height: 100%;
    float: left;
    width: 100%;
}

div.container1 {
    height: 17px;
    float: right;
    width: 100%;
}

div.positive4 {
    background-color: #0059ff
}

div.positive,
div.positive1,
div.positive3, 
div.positive5 {
    background-color: rgb(99, 150, 244)
}

table {
    vertical-align: middle;
    text-align: left;
    border-collapse: collapse;
}
td {
    height: 20px;
}

th {
    height: 20px;
}

td,
th {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
}

th,
tr.odd {
    background-color: #e1e1e1;
}

td.chart {
    background-color: transparent
}


th {
    padding-left: 10px;
    border-bottom: 2px solid rgba(160, 152, 152, 0.667);
}

th.total {
    text-align: right;
}

td.data {
    padding-left: 10px;

}

td.value {
    text-align: right;
}

tr.bordered {
    border-top: 1px solid #000;
    font-weight: 600;
}

.tdLeftBorder {
    border-left: 1px dotted blue;
}

.tdRightBorder {
    border-right: 1px dotted blue;
    margin-right: 0px;
}

div.rightBarText {
    position: relative;
    background-color: transparent !important;
    padding: 0;
    z-index: 1;
}

.barRightToLeft {
    position: absolute;
    top: 0;
    background-color: rgb(99, 150, 244);
    right: 0;
    z-index: 0;
}

.barLeftToRight {
    /* position: absolute; */
    margin-top: -18px;
    z-index: -1;
}