// $secondary: red;
.powerbi-iframe {
  width: 100%;
  height: 100vh;
}
.theme-settings {
  display: none !important;
}
.sidebar-inner > div > .nav-item:first-of-type .nav-link {
  font-size: 0.8rem;
}
.sidebar::-webkit-scrollbar,
.sidebar-inner::-webkit-scrollbar {
  display: none;
}
.sidebar,
.sidebar-inner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.avatar-lg img {
  object-fit: cover;
}
.mobile-nav-text {
  font-size: 0.8rem;
  text-transform: none;
}
.navbar-brand img {
  width: 30px;
  height: auto;
  margin-right: 0.5rem;
}
.navbar-toggler-icon {
  width: 1em;
  height: 1em;
}